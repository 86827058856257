.basicLayout {
	box-sizing: border-box;
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding: calc(var(--bs-gutter-x) * 0.5) calc(var(--bs-gutter-x) * 1);
}
.indicatorBox {
	margin: 2rem 5px;
	padding: 15px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	min-width: 7rem;
	max-width: 9rem;
	height: 10rem;
	background-color: #ebf2ff;
	border: none;
	border-radius: 10px;
	text-align: center;
	justify-content: space-between;
	p {
		color: #486bff;
	}
}

#summary-index li:first-child {
	margin-left: 0;
}
#summary-index li:last-child {
	margin-right: 0;
}

.recommendProductBtn {
	padding: 8px 13px;
	margin: 15px 0 10px;
	border-radius: 20px;
	background-color: #7690ff;
	color: #fff;
	font-size: 16px;
	max-width: 195px;
	text-align: center;
	cursor: pointer;
}

#chart-box {
	// flex: 1;
	display: flex;
	flex-direction: column;
	height: 10rem;
	align-items: center;
	justify-content: center;
	// background-color: #e0e4f4;
	position: relative;
	font-size: 15px;

	.chartLine {
		height: 20px;
		border-radius: 20px;
		width: 100%;
		background: linear-gradient(to right, rgba(118, 144, 255, 1), rgba(145, 192, 255, 1));
	}
	.left {
		position: absolute;
		bottom: 1rem;
		left: 3px;
	}
	.right {
		position: absolute;
		bottom: 1rem;
		right: 3px;
	}
	.verticalLine {
		position: absolute;
		width: 1px;
		height: 33px;
		background-color: #4b4b4b;
	}
	.userIndex {
		position: absolute;
		top: 20px;
		animation: fadein 1s;

	}
	@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
	
	.userbox {
		width: 30px;
		height: 30px;
		background-color: #7690ff;
		text-align: center;
		color: #fff;
		border-radius: 10px;
	}
	.userLine {
		width: 1px;
		height: 30px;
		background-color: #4b4b4b;
	}

	// 결과 페이지 bar 차트 //
	.progressBarBox {
		margin: 100px auto;
		width: 100%;
		text-align: center;
	}
	.progress2 {
		padding: 5px;
		border-radius: 30px;
		background: rgba(193, 212, 229, 0.3);  
		box-shadow: inset 0 1px 2px rgba(42, 47, 57, 0.3), 0 1px rgba(247, 249, 251, 0.4);
		// transform: rotate(180deg);
	}
	
	.progress-bar2 {
		height: 18px;
		border-radius: 30px;
		background-image: 
			linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
		transition: 0.4s linear;  
		transition-property: width, background-color; 
	}
	
	.progress-moved .progress-bar2 {
		background-color: rgba(118, 144, 255, 1);  
		transition: transform 2s ease-in-out;
		animation: progressAnimation 5s;
	}
}
// background: linear-gradient(to right, rgba(118, 144, 255, 1), rgba(145, 192, 255, 1));

@keyframes progressAnimation {
  0%   { width: 5%; background-color:rgba(145, 192, 255, 1 )}
  100% { width: width; background-color: rgba(118, 144, 255, 1)}
}

#chart-collection,
#life-index {
	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		margin-top: 20px;
		background-color: #f7f7f7;
		border-radius: 10px;
	}
	.barChart {
		margin: 1.5rem 0 2rem;
		p {
			text-align: center;
			margin: 0;
			font-size: 14px;
		}
		.text {
			min-height: 1.8rem;
		}
		.userLocation {
			position: absolute;
			top: -11px;

			.index {
				white-space: nowrap;
			}
		}
		.left {
			position: absolute;
			bottom: 0;
			left: 4px;
		}
		.right {
			position: absolute;
			bottom: 0;
			right: 4px;
		}
	}
	.blue {
		flex: 1;
		height: 7px;
		background-color: #7690ff;
		margin: 3px;
		// margin-left: 7px;
	}
	.green {
		flex: 1;
		height: 7px;
		background-color: #29ad9e;
		margin: 3px;
	}
	.yellow {
		flex: 1;
		height: 7px;
		background-color: #f0b961;
		margin: 3px;
	}
	.tomato {
		flex: 1;
		height: 7px;
		background-color: #ff8181;
		margin: 3px;
		// margin-right: 7px;
	}
}

#recommend-product,
#lacto-list {
	.subHeader {
		font-size: 18px;
		font-weight: 700;
		margin-top: 1.5rem;
		margin-bottom: 5px;
		color: #4b4b4b;
		white-space: normal;
	}

	li {
		border-radius: 15px;
		font-size: 12px;
		padding: 20px;
		margin: 5px 0 20px;
	}

	.shadow {
		// border-radius: inherit;
		box-shadow: 0px 2px 6px rgb(0 0 0 / 15%) !important;
		transition: $transition-base;
	}

	.contentsList {
		li {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 12px 10px;
			margin-top: 20px;
			background-color: #f7f7f7;
			border-radius: 10px;
			height: 110px;
		}

		span {
			font-size: 15px;
			text-align: start;
		}
	}
	.moreContents {
		font-size: 15px;
		color: rgba(118, 144, 255, 1);
		font-weight: 500;
		text-align: end;
	}

	.horizontal {
		border: 1px solid #eaeaea;
		height: 1px;
		width: 96%;
		padding: 0 10px;
		margin: 7px;
	}
}
.lactoItem {
	display: flex;
	// justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	.lactoImageBox {
		width: 70px;
		height: 70px;
		aspect-ratio: 1/1;
		object-fit: cover;
		text-align: center;
		border-radius: 10px;

		background-image: url('../../assets/logos/chekitLogo.png');
		background-color: #e7ebfa;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		// flex: 1;
	}
	// .contents-box {
	// 	// flex: 3.2;
	// }
	p {
		margin-bottom: 4px;
	}
	span {
		color: #7c7c7c;
		position: absolute;
		padding: 5px;
		bottom: 4px;
		right: 10px;
	}
}

#common-footer {
	--bs-gutter-x: 1rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding: calc(var(--bs-gutter-x) * 0.7) calc(var(--bs-gutter-x) * 1.2);
	margin: 0;
	background-color: #f7f7f7;

	.transition {
		transition: border 0.5s ease-in-out;
	}

	.FaqContents {
		// font-size: 16px;
		visibility: hidden;
		// width: 100%;
		// transition: visibility 0s, opacity 0.5s linear;
		// opacity: 0;
		height: 0;
		transition: height 0ms 800ms, opacity 500ms 0ms;
		overflow: hidden;
	}

	// -- webkit-transition(opacity 1s ease-in);

	.isActive {
		padding: 10px 5px;
		visibility: visible;
		// opacity: 1;
		height: auto;
		transition: height 800ms 0ms, opacity 500ms 0ms;
	}
}
.barChartBox {
	text-align: center;
	margin: 0 10px 5px;
	aspect-ratio: 2.5/3;
	object-fit: contain;
}

.isHidden {
	visibility: hidden;
	// opacity: 0;
	// width: 100%;
	// transition: visibility 0s, opacity 0.5s linear;
	// opacity: 0;
	height: 1rem;
	transition: height 1500ms linear;
	overflow: hidden;
	background-color: #29ad9e;
}

.isVisible {
	padding: 10px 5px;
	visibility: visible;
	// opacity: 1;
	height: auto;
	// transition: height 800ms 0ms, opacity 500ms 0ms;
}

.statusBadge {
	margin: 7px 0;
	border-radius: 15px;
	padding: 3px 15px;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
}

#MicrobiomeType {
	.contents-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
	}
	.borderBottom {
		border-bottom: 1px solid #eaeaea;
	}
	.m-20 {
		margin-top: 20px;
	}
	.mr-1 {
		margin-right: 7px;
	}
	// :last-child {
	// 	border-bottom: none;
	// }
}

#vaginal-secretion {
	.title {
		font-size: 20px;
		align-items: center;

		p {
			padding: 5px;
			margin-left: 10px;
			margin: 5px;
		}
	}
	.window {
		max-width: 480px;
		overflow-x: scroll;
		overflow-y: hidden;
	}

	.window::-webkit-scrollbar {
		display: none;
	}
	.horizontalScroll {
		display: flex;
		// width: 200%;
	}
	.horizontalScroll:last-child {
		margin-right: 0;
	}
	.itemContainer {
		padding: 15px;
		border-radius: 15px;
		background-color: #f0f3ff;
		height: 285px;
		// width: 315px;
		margin: 3px 10px;
		aspect-ratio: 1.1/1;

		p {
			margin: 10px 5px;
		}
	}
}

#habbit-list {
	.habbitItem {
		border-bottom: 1px solid #eaeaea;
		.title {
			max-width: 360px;
			word-break: break-word;
		}
	}
	li:last-child {
		border-bottom: none;
	}
}

.typeGuide {
	position: absolute;
	top: 110px;
	left: 110px;
	// transition: visibility all 500ms;
}

#dounut-chart {
	Chart {
		flex: 2.8;
		max-width: 245px;
	}
}

#index-box {
	flex: 1.2;
	min-width: 52px;
	margin-left: 3px;
	// text-align: center;
	align-items: center;
	// justify-content: center;

	.itembox {
		height: 30px;
		margin: 5px 0;
	}
	.colorChip {
		border-radius: 50px;
		width: 15px;
		height: 15px;
	}
}

.footerText {
	text-align: start;
	font-size: 15px;
	font-weight: 500;
}

.modalImageAni {
	// animation: modalImageAni 1.8s infinite linear;
	// animation: fly 2.5s linear infinite; /* 애니메이션 적용 */
}

@keyframes modalImageAni {
	0% {
		transform: translate(0px, 0px);
	}
	25% {
		transform: translate(8px, -12px);
	}
	50% {
		transform: translate(13px, -7px);
	}
	50% {
		transform: translate(5px, -18px);
	}
	100% {
		transform: translate(0px, 0px);
	}
}

@keyframes fly {
	0% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(50%);
	}
}
