//
//	Wizard
//

$wizard-button-size: 2rem;

.wizard-progress {
	margin: ($wizard-button-size * 0.3) ($wizard-button-size * 0.5)
		(($wizard-button-size * 0.4) + $spacer);

	> .progress {
		height: 6px;
	}

	.category-title {
		padding: 0;
		margin: 3px 0px 6px;
	}
	.title-text {
		font-size: 18px;
		font-weight: 600;
		color: #444;
		
	}
	.wizard-progress-btn {
		width: $wizard-button-size;
		height: $wizard-button-size;
	}
}
