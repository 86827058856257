//
// Custom
//

@use 'sass:map';

/*
* result page css
**/
@import 'mvp_page_wrapper';
@import 'kit_delivery';
@import 'survey';
@import 'result_page';

// @media (min-width: 640px){
// .mobile-screen {
//     width: 480px!important;
// }

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}
.pink {
	background-color: pink;
}
.f-main {
	color: #738cff;
}
.f-main-strong {
	color: #5170ef;
}
.f-sub {
	color: #4b4b4b;
}
.f-black {
	color: #000;
}
.f-gray {
	color: #676767;
}
.imageBox {
	background-color: #edf2ff;
	width: 120px;
	aspect-ratio: 1/1;
}


ul li,
li {
	margin: 0;
	padding: 0;
	text-indent: 0;
	list-style-type: 0;
}

button {
	margin: 0;
	padding: 0;
	border: 0;
	background: transparent;
	cursor: pointer;
	outline: none;
}

.position {
	position: relative;
}
.center-box {
	display: block;
	margin: 0 auto;
}

.flex {
	display: flex;
}
.column {
	flex-direction: column;
}

.center {
	justify-content: center;
	align-items: center;
}
.space-between {
	justify-content: space-between;
	align-items: center;
}

.flex-wrap {
	flex-wrap: wrap;
}
.flex-1 {
	flex: 1;
}

.align-center {
	align-items: center;
}

.mt-1 {
	margin-top: 1rem;
}
.mt-1 {
	margin-top: 2rem;
}
.mt-3 {
	margin-top: 3rem;
}
.mt-5 {
	margin-top: 5rem;
}
.mb-0 {
	margin-bottom: 0;
}
.pv-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}
.pv-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}
.ph-4 {
	padding-left: 4px;
	padding-right: 4px;
}
.ph-10 {
	padding-left: 12px;
	padding-right: 12px;
}
.mv-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}
.mv-20 {
	margin-top: 20px;
	margin-bottom: 20px;
}
.mv-25 {
	margin-top: 25px;
	margin-bottom: 25px;
}
.mv-50 {
	margin-top: 50px;
	margin-bottom: 50px;
}
.mh-10 {
	margin-left: 3px;
	margin-right: 10px;
}
.mh-25 {
	margin-left: 25px;
	margin-right: 25px;
}
.pl-10 {
	padding-left: 10px;
}
.margin-right {
	margin-right: 4px;
}
/*
* font size 지정
**/
.f14 {
	font-size: 14px;
}
.f15 {
	font-size: 15px;
}
.f16 {
	font-size: 16px;
}
.f18 {
	font-size: 18px;
}
.f20 {
	font-size: 20px;
}
.f22 {
	font-size: 22px;
}
.f25 {
	font-size: 25px;
}
.f27 {
	font-size: 27px;
}
.f30 {
	font-size: 30px;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}
.lineSpace {
	margin-top: 5px;
	margin-bottom: 5px;
}

/*
* border css 지정
**/
.br-10 {
	border-radius: 10px;
}

.border-bottom {
	border-bottom: 1px solid #eaeaea;
}

.bg-white {
	background-color: #fff;
}
ol,
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.hide {
	display: none;
}
.coverup {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0;
	left:0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.h100vh {
	height: 100vh;
}
.h90vh {
	height: 90vh;
}
.h100 {
	height: 100%;
}
.h85 {
	height: 83vh;
}
.h75 {
	height: 75vh;
}
.overflow-scroll {
	overflow-y: scroll;
	// overflow-x: hidden;
}
.overflow-hidden {
	overflow-x: hidden;
}

.result-page {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}

.shadow {
	box-shadow: 0px 5px 5px #888;
}

.WithoutGutterLayout {
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;
}
.text-center {
	text-align: center;
}

.padding-layout {
	padding-left: 0px !important;
	padding-right: 0px;
}


.commonBtn {
	border-radius: 29px;
	border: 1.8px solid #738cff;
	background-color: #fff;
	font-weight: 500;
	color: #738cff;
	width: 100%;
	min-height: 40px;
	padding: 10px;
	text-align: center;
	cursor: pointer;

	transition: all 0.5s;

	&.active {
		background-color: #738cff;
		color: #fff;
		transition: all 0.5s;
	}
	&.sqaure {
		border-radius: 16px;
	}
	&.consentBtn {
		text-align: left;
	}
	&.opacity {
		opacity: 0;
	}
}
.logoBox {
	width: 230px;
	height: 130px;
}

#p3CouponModal {

	.coupon-modal-header {
		flex-direction: column-reverse;
		background-color: #f4f4f4;
	}
	.copyBtn {
		width: 200px;
		padding: 7px;
		background-color: #7690ff;
		border-radius: 20px;
		border: 0px;
		font-size: 16px;
	}
	input {
		width: 100%;
		min-height: 50px;
		border-radius: 10px;
		border: 1px solid #d0d0d0;
	}
}

.scroll-box {
	overflow: scroll;
	// padding-right: 5px;
	height: 51vh;
	// background-color: pink;
	@media screen and (min-width: 600px) {
		height: 45vh;

	}
}

/* 아래의 모든 코드는 영역::코드로 사용 */
.scroll-box::-webkit-scrollbar {
	width: 7px; /* 스크롤바의 너비 */
}

.scroll-box::-webkit-scrollbar-thumb {
	height: 15px; /* 스크롤바의 길이 */
	background: #f3f0ff; /* 스크롤바의 색상 */
	border-radius: 10px;
	border: none;
}

.scrollbox::-webkit-scrollbar-track {
	background: rgba(254, 254, 254, 0.1); /*스크롤바 뒷 배경 색상*/
}


.grow {
	flex: 1;
}
.topSpace {
	margin-top: 25px;
}
.topSpace2 {
	margin-top: 50px;
}
.topSpace3 {
	margin-top: 75px;
}
.topSpace4 {
	margin-top: 100px;
}
.bottomSpace {
	margin-bottom: 22px;
}
.bottomSpace2 {
	margin-bottom: 6vh;
}
.bottomSpace4 {
	height: 12vh;
}
.space {
	margin-top: 2.5vh;
}
.space2 {
	margin-top: 5vh;
}
.space3 {
	margin-top: 7.5vh;
}
.space4 {
	margin-top: 10vh;
}
.mr-1 {
	padding-right: 3.5vw;
}

.sectionLine {
	background-color: #f5f5f5;
	height: 0.8rem;
	margin-top: 20px;
	// width: 110vw;
}
.horizontalLine {
	color: #ebebeb;
	position: absolute;
	top: 25px;
	background-color: #ebebeb;
	height: 2.5px;
	width: 80%;
	left: 40px;
	z-index: -2;
}
.lactoHorizontalLine {
	color: #ebebeb;
	position: absolute;
	top: 25px;
	background-color: #ebebeb;
	height: 2.5px;
	width: 75%;
	left: 50px;
	z-index: 0;
}

#welcome-page,
#lacto-delivery-page {
	a {
		list-style: none;
	}
	.testDate {
		font-size: 27px;
		font-weight: 500;
		border-bottom: 1px solid #eaeaea;
	}

	.lactoRecommandBox {
		padding: 16px 16px 25px;
		text-align: center;
		border-radius: 15px;
		background-color: #f8f8f8;
		img {
			margin-bottom: 18px;
		}
		.subText {
			color: #898989;
			font-size: 14px;
			font-weight: 500;
		}
		&.delivery {
			text-align: start;
			padding: 16px 20px;

			p {
				margin-bottom: 0.3rem;
			}
		}
	}
	.lactoBadge {
		border: 1.5px solid #738cff;
		border-radius: 10px;
		color: #738cff;
		font-weight: 600;
		font-size: 12px;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 10px;
		line-height: 16px;
	}
}
.welcomeContent {
	padding: 15px 0 5px;
	span {
		margin: 25px 0 10px;
		color: #777;
		font-size: 12px;
		font-weight: 500;
	}
	.icon {
		font-weight: 200;
	}
	.registeredListItem {
		display: flex;
		white-space: nowrap;
		@media screen and (max-width: 360px) {
			.desc .productName {
				// background-color: pink;
				overflow: hidden;
			}
		}

		.desc {
			flex: 3;
		}
		.state {
			flex: 1;
			text-align: right;
			align-items: center;
			margin-top: 50px;
			margin-right: 5px;
			font-size: 18px;
			font-weight: 700;
			color: #6782f1;
		}
	}
}
.bottomLine {
	border-bottom: 1px solid #eaeaea;
}

.KakaoShareButton {
	margin: 20px 0;
	padding: 16px;
	text-align: center;
	border-radius: 15px;
	background-color: #f8f8f8;
	width: 100%;		
	position: relative;


	.kakaoIconBox {
		margin:0 15px 0 5px;
		display: flex;
		align-items: center;
	}
	img {
		max-width: 55px;
		aspect-ratio: 1/1;
	}
	.contentsBox {
		display: flex;
		flex-direction: column;
		text-align: left;
		justify-content:center;
		margin: 0 8px;

		p {
			margin: 0;
			line-height: 26px;
		}
		p:nth-child(2) {
			color: #898989;
			line-height: 22px;

		}
	}
	.shareIcon {
		position: absolute;
		right :17px;
		top:12px;
	}
}

.welcomeProgress {
	justify-content: space-around;
	.box {
		display: flex;
		justify-content: center;
		border-radius: 50%;
		width: 45px;
		height: 45px;
		text-align: center;
		padding: 5px;
		background-color: #ebebeb;
		object-fit: contain;

		&.active {
			background: #738cff;
		}
		img {
			width: 32px;
			height: 32px;
			aspect-ratio: 1/1;
		}
		img &.active {
			width: 32px;
			height: 32px;
			aspect-ratio: 1/1;
		}
	}
	.progressItem {
		z-index: 1;
	}

	p {
		color: #777;
		margin-top: 10px;
		border: none;

		&.active {
			color: #000;
			font-weight: 700;
			border: none;
		}
	}
}
.buttonPadding {
	padding: 0.5rem 1.5rem * 0.5;
}


.contentContainer {
	padding: 5px 0;
	height: auto;
	margin: 20px 0 10px;
	
}

.buttonBox {
	position: absolute;
	bottom: 0.5rem;
	width: 100%;
	padding: 10px;
}

.stickyBox {
	position: fixed;
	background-color: #fff;
	bottom: -1px;
	width: 100%;
	padding:10px 10px 18px;
	
	@media screen and (min-width: 601px) {
		// width: 390px;
		position: sticky;
		bottom: -1px;
	}
}


.stickyButtonBox {
	position: fixed;
	background-color: #fff;
	bottom:0;
	width: 100%;
	padding:10px 10px 18px;
	
	
	@media screen and (min-width: 601px) {
		position: absolute;
		bottom: 0.5rem;
		width: 100%;
		padding: 10px;
		// width: 390px;
		// bottom: 0;
		// position: sticky;
		&.survey {
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			bottom: 0.5rem;
		}
	}
}

.commonBtnBox {
	position: absolute;
	bottom: 0.5rem;
	width: 100%;
	padding: 10px;
	background-color: #fff;
}

/*
* bottom sheet custom css
**/

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6); // 불투명 덮개 스크린
  --rsbs-bg: #fff;
	// --rsbs-max-width: 480px;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14); // bottom sheet handler
  --rsbs-max-w: 480px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 16px;
}

.bottomSheetContainer {
	.kakaoLogo {
		text-align: center;
		margin: 15px auto;
	}
	p{
		color: #898989;
		font-size: 17px;
		margin: 0;
	}

	button {
		// background-color: pink;
		align-items: center;
		padding: 10px;

		p{
			margin: 0;
			color: #486BFF;
			font-size: 16px;
			font-weight: 700;
			padding: 10px;
		}
	}
	.closeButton {
		position: absolute;
		top: 20px;
		right: 20px;
	}
}

.welcomeBox {
	width: 98%;
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -45%);

	display: flex;
	justify-content: center;
	align-items: center;
}