//
// Elements
// Settings - Tools - Generic - ELEMENTS - Objects - Components - Vendors - Utilities
//

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 0;
	margin: 0;

	// @media (max-width: 375px) and (max-height: 812px) {
	// 	/* iPhone X, XS, 11 Pro 등의 베젤 스타일링 */
	// 	padding-top: env(safe-area-inset-bottom);
	// 	padding-bottom: env(safe-area-inset-bottom);
	// 	padding-left: env(safe-area-inset-left);
	// 	padding-right: env(safe-area-inset-right);
	// 	background-color: #f8f8f8; /* 베젤 색상 설정 */
	// }
	// /* 베젤 부분 스타일링 */
	// /* 아이폰 X 이상의 모델을 가정하고, 기기 크기 및 배경색에 따라 조정이 필요할 수 있습니다 */

	// @media (max-width: 414px) and (max-height: 896px) {
	// 	/* iPhone XR, 11 등의 베젤 스타일링 */
	// 	padding-top: env(safe-area-inset-top);
	// 	padding-bottom: env(safe-area-inset-bottom);
	// 	padding-left: env(safe-area-inset-left);
	// 	padding-right: env(safe-area-inset-right);
	// 	background-color: #f8f8f8; /* 베젤 색상 설정 */
	// }
}

code {
	// font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	font-family: 'AppleSDGothicNeo', 'Noto Sans KR', sans-serif;
}

[os='Windows'] {
	::-webkit-scrollbar {
		width: 0.75rem;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 1rem;
	}

	::-webkit-scrollbar-track {
		border-radius: 1rem;
		box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0);
	}

	:hover::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

// stylelint-disable selector-max-specificity
// stylelint-disable selector-max-id
// stylelint-disable scale-unlimited/declaration-strict-value
#portal-notification {
	position: fixed;
	z-index: 9999;
}
// stylelint-enable selector-max-specificity
// stylelint-enable selector-max-id
// stylelint-enable scale-unlimited/declaration-strict-value
