*,
*::before,
*::after {
	box-sizing: border-box;
}

.background {
	width: 100%;
	min-width: 350px;
	background-color: #F7F9FB;

	@media screen and (min-width: 600px) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr; 
  	// grid-gap: 3px; 

		.pc-contents {
			display: block;
			background-color: #F7F9FB;
			display: flex;
			justify-content:right;
			align-items: center;

			img {
				// width: 280px;
				width: 320px;
			}
		}
		.mobile-contents {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.mvp-page-wrapper {
			width: 390px;
			height: 740px;
			overflow-y: scroll;
			border-radius: 1rem;
			background-color: #fff;
		}

	}
	@media screen and (max-width:600px) {
		.pc-contents {
			display: none !important;
		}
		
		.mvp-page-wrapper {
			width: 100%;
			// height: 100%;
			background-color: #fff;
			// overflow-y: scroll;
		}
	}
}
