#barcode-page {
	.guideText {
		font-weight: 500;
		font-size: 13px;
		color: #afafaf;
	}
	.barcodeInput {
		padding: 20px;
		border: none;
		border-radius: 15px;
		background-color: #f0f2f5;
		font-size: 18px;
		font-weight: 500;
		line-height: 19px;
		width: 100%;
		text-align: center;
	}
	.helpButton {
		border: none;
		background-color: #fff;
	}

	.popup-button {
		display: block;
		text-align: center;
		text-decoration: none;
		font-weight: 800;
		font-size: 1em;
		text-transform: uppercase;
		color: white;

		//Button shape & animation
		border-radius: 20px;
		margin: 5px 0;
		padding: 1em 0.8em;
		background-size: 200% auto;
		color: white;
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
		background-image: linear-gradient(to right, #738cff 0%, #9dd8fc 50%, #738cff 100%);
		transition: 0.5s;
		&:hover {
			background-position: right center;
		}
		&::before {
			width: 10px;
			height: 10px;
			background-color: pink;
		}
	}
}

@keyframes btnAni {
	25% {
		background-color: rgb(188, 99, 221);
	}
	50% {
		background-color: rgba(101, 102, 255);
	}
	75% {
		background-color: rgb(188, 99, 221);
	}
}
.overflow-hidden {
	overflow: hidden;
}

.login {
	height: auto !important;
}

#guide-page {
	&.welcome {
		position: relative;
		overflow: hidden;
	}

	.welcomeImage {
		object-fit: cover;
		width: 100%;
		overflow: hidden;
	}

	.welcomeBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: fixed;
		bottom:1.5rem;

		@media screen and (min-width: 601px) {
			position: absolute;
		}

	}
	.btnTest {
		height: 60px;
		background-color: rgba(101, 102, 255);
		border: none;
	}

	ul {
		li {
			display: flex;
			justify-content: space-around;
			margin: 10px;
			padding: 5px;
			.imageBox {
				background-color: #fff;
				max-width: 110px;
				object-fit: contain;
				text-align: center;
				aspect-ratio: 1/0.8;
			}
		}
		.guideText {
			margin-left: 5vw;
			display: flex;
			align-items: center;
			padding-top: 2vh;
			font-size: 14px;
			font-weight: 500;
			color: #6a6a6a;
		}
	}
}

#consent-page {
	.consentItem {
		margin: 5px 10px;
		margin-right: 15px;
		p {
			padding-top: 5px;
			font-size: 10px;
			text-decoration: underline;
		}
	}
	.itemBox {
		padding: 3px 0 8px;
		border-bottom: 1px solid #e8e8e8;
	}
	label {
		padding: 5px 0;
		color: #afafaf;
		border: none;
		font-size: 12px;
		span {
			color: #555;
		}

		&.active {
			color: #738cff;
			span {
				color: #000;
			}
		}
	}
	.signPad {
		border: 2px solid #e8e8e8;
		border-radius: 10px;
		position: relative;
		margin-top: 2.5vh;

		.canvasPlaceholder {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #adb5bd;
			font-size: 20px;
			font-weight: 600;
		}

		.clearBtn {
			position: absolute;
			top: 5px;
			right: 5px;
			border: none;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #d6d7db;
			color: #fff;
			text-align: center;
			padding: 0;
		}
	}
}
.embedModalBody {
	height: 500px;
	max-width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	// padding: px;
}
.indexNum {
	margin: 0px;
	color: #bcbcbc;
	font-size: 14px;
}
.videoBox {
	width: 100%;
	aspect-ratio: 5/3;

	&.youtube {
		background-color: #e7e9f5;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.youtube {
	background-color: #edeff5;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.underlineInput {
	border-bottom: 1px solid #738cff;
	img {
		margin: 10px;
	}
}
.addressInput {
	border: none;
	padding: 6px;
	margin: 5px 0;
	font-size: 15px;
}
.postCode {
	background: rgba(0, 0, 0, 0.25);
	position: absolute;
	height: 100vh;
	z-index: 9000;
	top: 10px;
	left: 0px;
	width: 100%;
}
.guideShadowBox {
	padding: 12px;
	margin: 15px;
	background: #ffffff;
	box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.08);
	border-radius: 15px;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	max-height: 80px;
	white-space: pre;
}

.badgeStyle {
	z-index: 1000;
	position: absolute;
	top: 5rem;
	right: 1rem;
	padding: 8px 10px;
	border-radius: 17px;
	background-color: #7690ff !important;
	color: #fff !important;
	font-size: 13px;
}

@import './_welcome_btn_animation.scss';
