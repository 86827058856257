.customInputBox {
	display: flex;
	flex: 1;
	background-color: #eff0f6;
	margin: 25px 0 10px;
	align-items: center;
	justify-content: center;
	min-height: 55px;
	padding: 6px 6px;
	border-radius: 8px;
	border: none;
}

.datePickerInput {
	border: none;
	outline: none;
	background: transparent;
	text-align: center;
	font-size: 20px;
	max-height: fit-content;
}
.datePickerIcon {
	width: 30px;
	height: 30px;
	border: none;
	border-radius: 8px;
}
.calendarSheet {
	margin: 0 auto;
	display: flex;
	justify-content: center;
}
.surveyButton {
	display: flex;
	font-size: 20px;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	border-radius: 6px;
	cursor: pointer;
	border: black;
	z-index: 10;
}

.commonHeader {
	margin-top: 5px;
	// margin-bottom: 1.7vh;
	font-size: 24px;
	line-height: 32px;
}
.survey-header {
	// margin-top: 10vh;
	margin-bottom: 1.5vh;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}
.welcomeSubTitle {
	margin: 10px 0;
	font-size: 13px;
	color: #929292;
}
.survey-sub-header {
	display: block;
	width: 100%;
	color: #777;
	text-align: left;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	// padding-bottom: 8px;
}
#contents-area {
	padding-top: 16px;
	padding-bottom: 72px;
}
#select-area {
	padding-top: 7px;
	padding-bottom: 10px;
}

label {
	font-size: 16px;
	line-height: 1.8rem;
	padding: 0.2em 0.4em;
}
label {
	vertical-align: top;
	cursor: pointer;
}

span {
	vertical-align: middle;
}

[type='radio'] {
	vertical-align: middle;
	appearance: none;
	border: max(2px, 0.1em) solid #d6d6d6;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	transition: border 0.5s ease-in-out;
	// padding: 5px;
}
.radioBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 26px;
	height: 26px;
	padding: 0;
	margin: 0;
}

[type='radio']:checked {
	border: 0.35em solid #738cff;
}

[type='radio']:focus-visible {
	outline-offset: max(2px, 0.1em);
	outline: max(2px, 0.1em) dotted #738cff;
}

[type='radio']:hover {
	box-shadow: 0 0 0 max(4px, 0.15em) lightgray;
	cursor: pointer;
}

[type='radio']:disabled {
	background-color: lightgray;
	box-shadow: none;
	opacity: 0.7;
	cursor: not-allowed;
}

[type='radio']:disabled + span {
	opacity: 0.7;
	cursor: not-allowed;
}

/** 
	**  [문진] INPUT BOX 
*/
.input-box {
	position: relative;
	width: 100%;
	height: 64px;
	z-index: 0;
	background-color: #eff0f6;
	top: 25px;
	opacity: 1;
	border-radius: 12px;
	color: transparent;
	outline: none;
}
.input-box.type-text input {
	position: absolute;
	z-index: 2;
	background-color: #eff0f6;
	left: 24px;
	height: 24px;
	width: calc(100% - 48px);
	transition: background-color linear 0.2s, top linear 0.2s, opacity linear 0.2s;
	bottom: 1px;
	opacity: 0;
	font-size: 16px;
	font-weight: 500;
	color: #222222;
	outline: none;
	border: none;
	margin-top: 5px;
}
.input-box.type-text input:valid {
	opacity: 1;
	top: 25px;
	background-color: #eff0f6;
	font-size: 14px;
	outline: none;
	border: none;
}
.input-box.type-text input:focus {
	background-color: #fff;
	top: 25px;
	opacity: 1;
	outline: none;
	border: none;
}
.input-box.type-text input + label {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #eff0f6;
	border: 1px solid #eff0f6;
	border-radius: 12px;
	letter-spacing: 0.75px;
	box-sizing: border-box;
	font-size: 15px;
	padding: 18px 24px;
	color: #6e7191;
	font-weight: normal;
	transition: padding-top linear 0.2s, font-size linear 0.2s, background-color linear 0.2s;
}
.input-box.type-text input:valid + label {
	padding-top: 3px;
	background-color: #eff0f6;
	// border: 1px solid #738cff;
	font-size: 14px;
}
.input-box.type-text input:focus + label {
	padding-top: 3px;
	background-color: #ffffff;
	border: 1px solid #738cff;
	font-size: 14px;
}

/** 
	* 설문지 하단 이전 다음 버튼
*/
.button-box {
	position: absolute;
	width: 100%;
	bottom: 0.5rem;
	background-color: transparent;
	justify-content: center;
	box-sizing: border-box;
}

.mvp-prev-button {
	flex: 1;
	height: 58px;
	line-height: 58px;
	background-color: #ffffff;
	border: 1.8px solid #738dffec;
	border-radius: 12px;
	font-size: 16px;
	font-weight: 700;
	color: #738cff;
	margin-right:10px;
}
.mvp-next-button {
	flex: 2;
	height: 58px;
	line-height: 58px;
	transition: background-color linear 0.2s, top linear 0.2s, opacity linear 0.2s;
	background-color: #dddee2;
	border: 2px solid #dddee2;
	border-radius: 12px;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	// margin-right: 10px;
}
.btnAct {
	background-color: #738cff;
	border: 2px solid #738cff;
}

.couponImg {
	display: block;
	margin: 1.4rem auto 0;
}

/** 
** 설문지 체크 버튼 기본 디자인 
*/
#checkList-area .bubbleItem {
	width: auto;
	margin-right: 10px;
	margin-bottom: 12px;
	text-align: center;
}
#checkList-area .basicItem {
	border-bottom: 1px solid #c6c6c6;
	padding: 8px 0px;
	margin: 5px 10px;
}
#checkList-area .basicItem span {
	max-width: 25rem;
}
#checkList-area .bubbleItem input[type='checkbox'] {
	display: none;
}
.bubbleItem label {
	display: inline-block;
	width: auto;
	height: 44px;
	line-height: 36px;
	border: 1px solid #888888;
	box-sizing: border-box;
	border-radius: 8px;
	font-weight: 700;
	font-size: 16px;
	color: #888888;
	padding: 5px 20px;
	box-sizing: border-box;
	cursor: pointer;
}

.bubbleItem .selected {
	background-color: #738cff;
	border: 1px solid #738cff;
	color: #fcfcfc;
}

.basicItem input[type='checkbox'] {
	border: 1px solid #d6d6d6;
	width: 20px;
	height: 20px;
	transition: border 0.5s ease-in-out;
}
.basicItem input[type='checkbox']:hover {
	box-shadow: 0 0 0 max(3px, 0.1em) lightgray;
	cursor: pointer;
}
.basicItem input[type='checkbox']:checked {
	border: 0.4em solid #738cff;
}

/** 
** [문진] 선택 리스트 기본 디자인 
*/
#select-area ul {
	max-height: 43vh;
	// background-color: pink;
}
#select-area .multiItem {
	border-bottom: 1px solid #c6c6c6;
	padding: 10px 0px;
	margin: 5px 10px;
}

#select-area .multiItem span {
	font-size: 17px;
	line-height: 23px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	max-width: 24.5rem;
}
.unselected {
	color: #888;
	font-weight: 400;
}
.selected {
	color: #738cff;
	font-weight: 500;
}

#select-area input[type='radio']:checked + span {
	color: #738cff;
	font-weight: 500;
}

.onlyTwoItem {
	display: flex;
	justify-content: center;
	min-width: 1vw;
	margin: 5px 8px;
	border-radius: 35px;
	border: 2px solid #c6c6c6;
	color: #888888;
	padding: 16px 16px;
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;

	&.active span {
		font-weight: 700 !important;
	}
}

.active {
	border: 2px solid #738cff;
	font-weight: 700;
}

.toggleButton {
	margin: 10px 0 5px;
}

.pre-line {
	white-space:pre-line;
}

#intermediate-box {
	display: table;
	text-align: center;    
	margin: 100px auto 0;

	.title {
		white-space:pre-line;
		font-size: 19px;
		font-weight: 700;
		// color: #738cff;
	}

	.next-icons  {
		display: flex;
		max-width: 380px;
		align-items: baseline;
		justify-content: center;
		:nth-child(1) {
			flex:3;
			max-width: 80%;
			// animation: bounce 2s infinite;
		}
		:nth-child(2) {
			flex:1;
			max-width: 75px;
			// max-height: 75px;
			aspect-ratio: 1/0.8;
		}
	}
}

// @keyframes bounce {
//     0%, 50%, 100% {transform: translateY(0);}
//     25% {transform: translateY(-8px);}
//     75% {transform: translateY(-8px);}
// } 