.ios-15 {
	--duration: 7s;
	--easing: linear;
	// --c-color-1: #{rgba(#12b886, 0.7)};
	--c-color-1: #{rgba(#6026f4, 0.7)};
	--c-color-2: #1016d7;
	--c-color-3: #c41be2;
	--c-color-4: #{rgba(#5947d2, 0.8)};
	--c-shadow: #{rgba(#bccde7, 0.4)};
	--c-shadow-inset-top: #{rgba(#80a5f0, 0.9)};
	--c-shadow-inset-bottom: #{rgba(#fffad7, 0.8)};
	--c-radial-inner: #a15ad4;
	--c-radial-outer: #eaf2fb;
	--c-color: #fff;
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	width: 85%;
	outline: none;
	position: relative;
	cursor: pointer;
	border: none;
	display: table;
	border-radius: 40px;
	padding: 0;
	margin: 0;
	text-align: center;
	font-weight: 600;
	font-size: 22px;
	letter-spacing: 0.02em;
	line-height: 1.5;
	color: var(--c-color);
	background: radial-gradient(circle, var(--c-radial-inner), var(--c-radial-outer) 80%);
	box-shadow: 0 0 14px var(--c-shadow);
	&:before {
		content: '';
		pointer-events: none;
		position: absolute;
		z-index: 3;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 36px;
		box-shadow: inset 0 3px 12px var(--c-shadow-inset-top),
			inset 0 -3px 4px var(--c-shadow-inset-bottom);
	}
	.wrapper {
		-webkit-mask-image: -webkit-radial-gradient(white, black);
		border-radius: 35px;
		// position: absolute;
		width:100%;
		padding: 18px 6px;
		overflow: hidden;

		
		span {
			display: inline-block;
			position: relative;
			z-index: 1;
		}
		.circle {
			position: absolute;
			left: 0;
			top: 0;
			width: 155px;
			height: 155px;
			border-radius: 50%;
			filter: blur(var(--blur, 8px));
			background: var(--background, transparent);
			transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
			animation: var(--animation, none) var(--duration) var(--easing) infinite;
			&.circle-1,
			&.circle-9,
			&.circle-10 {
				--background: var(--c-color-4);
			}
			&.circle-3,
			&.circle-4 {
				--background: var(--c-color-2);
				--blur: 16px;
			}
			&.circle-5,
			&.circle-6 {
				--background: var(--c-color-3);
				--blur: 16px;
			}
			&.circle-2,
			&.circle-7,
			&.circle-8,
			&.circle-11,
			&.circle-12 {
				--background: var(--c-color-1);
				--blur: 13px;
			}
			&.circle-1 {
				--x: 0;
				--y: -30px;
				--animation: circle-1;
			}
			&.circle-2 {
				--x: 282px;
				--y: 18px;
				--animation: circle-2;
			}
			&.circle-3 {
				--x: -2px;
				--y: -12px;
				--animation: circle-3;
			}
			&.circle-4 {
				--x: 120px;
				--y: -12px;
				--animation: circle-4;
			}
			&.circle-5 {
				--x: 122px;
				--y: -4px;
				--animation: circle-5;
			}
			&.circle-6 {
				--x: 156px;
				--y: 26px;
				--animation: circle-6;
			}
			&.circle-7 {
				--x: 58px;
				--y: 38px;
				--animation: circle-7;
			}
			&.circle-8 {
				--x: 228px;
				--y: -4px;
				--animation: circle-8;
			}
			&.circle-9 {
				--x: 20px;
				--y: -12px;
				--animation: circle-9;
			}
			&.circle-10 {
				--x: 64px;
				--y: 16px;
				--animation: circle-10;
			}
			&.circle-11 {
				--x: -24px;
				--y: 8px;
				--animation: circle-11;
			}
			&.circle-12 {
				--blur: 14px;
				--x: 62px;
				--y: 4px;
				--animation: circle-12;
			}
		}
	}
}

@keyframes circle-1 {
	34% {
		transform: translate(-10px, 36px) translateZ(0);
	}
	67% {
		transform: translate(10px, 84px) translateZ(0);
	}
}

@keyframes circle-2 {
	33% {
		transform: translate(230px, -30px) translateZ(0);
	}
	66% {
		transform: translate(262px, -68px) translateZ(0);
	}
}

@keyframes circle-3 {
	33% {
		transform: translate(10px, 32px) translateZ(0);
	}
	66% {
		transform: translate(22px, 64px) translateZ(0);
	}
}

@keyframes circle-4 {
	33% {
		transform: translate(96px, -32px) translateZ(0);
	}
	66% {
		transform: translate(132px, -28px) translateZ(0);
	}
}

@keyframes circle-5 {
	33% {
		transform: translate(104px, 48px) translateZ(0);
	}
	66% {
		transform: translate(60px, -52px) translateZ(0);
	}
}

@keyframes circle-6 {
	33% {
		transform: translate(68px, -36px) translateZ(0);
	}
	66% {
		transform: translate(116px, -76px) translateZ(0);
	}
}

@keyframes circle-7 {
	33% {
		transform: translate(78px, 48px) translateZ(0);
	}
	66% {
		transform: translate(130px, -80px) translateZ(0);
	}
}

@keyframes circle-8 {
	33% {
		transform: translate(152px, -24px) translateZ(0);
	}
	66% {
		transform: translate(176px, -40px) translateZ(0);
	}
}

@keyframes circle-9 {
	33% {
		transform: translate(40px, -32px) translateZ(0);
	}
	66% {
		transform: translate(80px, -28px) translateZ(0);
	}
}

@keyframes circle-10 {
	33% {
		transform: translate(98px, 30px) translateZ(0);
	}
	66% {
		transform: translate(160px, 18px) translateZ(0);
	}
}

@keyframes circle-11 {
	33% {
		transform: translate(-24px, -30px) translateZ(0);
	}
	66% {
		transform: translate(-34px, -20px) translateZ(0);
	}
}

@keyframes circle-12 {
	33% {
		transform: translate(66px, 0px) translateZ(0);
	}
	66% {
		transform: translate(100px, -52px) translateZ(0);
	}
}

// html {
//   box-sizing: border-box;
//   -webkit-font-smoothing: antialiased;
// }

// * {
//   box-sizing: inherit;
//   &:before,
//   &:after {
//       box-sizing: inherit;
//   }
// }

// dribbble & twitter
// body {
//   overflow: hidden;
//   min-height: 100vh;
//   font-family: 'Inter';
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #1B1E4F;
//   .dribbble {
//       position: fixed;
//       display: block;
//       right: 20px;
//       bottom: 20px;
//       img {
//           display: block;
//           width: 76px;
//       }
//   }
//   .twitter {
//       position: fixed;
//       display: block;
//       right: 112px;
//       bottom: 14px;
//       svg {
//           width: 24px;
//           height: 24px;
//           fill: white;
//       }
//   }
// }
