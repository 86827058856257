//
//  Page
//

.page {
	@include padding-top(map-get($page, padding-y));

	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	// position: relative;


	@include only-safari {
		height: calc(100% - #{map-get($footer, height)});
	}
}
